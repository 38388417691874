import classNames from 'classnames'
import {flattenDeep, uniq} from 'lodash'
import {useEffect, useMemo, useRef, useState} from 'react'

import {CloseS} from '@/assets/specify'
import {translationNamespaces} from '@/common/enums'
import {TChannelsV2Channel, TChannelsV2Filters} from '@/common/types'
import {notUndefined} from '@/common/utils'
import Button from '@/components/Button'
import {useTranslations} from '@/hooks/useTranslations'

import styles from './ChannelsV2Filters.module.scss'

function getLabels(
  channels: TChannelsV2Channel[],
  property: Extract<keyof TChannelsV2Channel['content'], 'categories' | 'industries' | 'countries'>
): string[] {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Unreachable code error
  return uniq(flattenDeep(channels.map((channel) => channel.content[property])))
    .filter(notUndefined)
    .sort()
}

function hasFilter(filter: string[], values: string[]): boolean {
  return filter.length >= 1 ? !filter.map((v) => values.includes(v)).some((v) => v === false) : true
}

function withCount(label?: string, count?: number): string | undefined {
  if (label && typeof count === 'number') {
    return label.replace('__', count.toString())
  }
}

const ChannelsV2Filters = ({
  channels,
  mobileSearchButton,
  searchHeading,
  searchPlaceholder,
  categoriesHeading,
  countriesHeading,
  industriesHeading,
  onChange = () => undefined,
  isVisible,
  onClose = () => undefined,
}: TChannelsV2Filters): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)

  const {t, getName} = useTranslations()
  const [search, setSearch] = useState('')
  const [activeCategories, setActiveCategories] = useState<string[]>([])
  const [activeIndustries, setActiveIndustries] = useState<string[]>([])
  const [activeCountries, setActiveCountries] = useState<string[]>([])
  const [results, setResults] = useState<TChannelsV2Channel[] | undefined>(channels || [])

  const categories: string[] = useMemo(
    () => (channels ? getLabels(channels, 'categories') : []),
    [channels]
  )

  const industries: string[] = useMemo(
    () => (channels ? getLabels(channels, 'industries') : []),
    [channels]
  )

  const countries: string[] = useMemo(
    () => (channels ? getLabels(channels, 'countries') : []),
    [channels]
  )

  function handleClose() {
    if (isVisible && containerRef.current) {
      containerRef.current.scrollTo(0, 0)
    }
    onClose()
  }

  useEffect(() => {
    if (channels) {
      if (
        activeCategories.length === 0 &&
        activeIndustries.length === 0 &&
        activeCountries.length === 0 &&
        !search
      ) {
        return setResults(undefined)
      }

      setResults(
        channels
          .filter((channel) => {
            const {categories = [], countries = [], industries = []} = channel.content

            const hasCategories = hasFilter(activeCategories, categories)
            const hasIndustries = hasFilter(activeIndustries, industries)
            const hasCountries = hasFilter(activeCountries, countries)

            return hasCategories || hasIndustries || hasCountries
          })
          .filter((channel) =>
            search ? channel.name.toLowerCase().startsWith(search.toLowerCase()) : true
          )
      )
    }
  }, [channels, activeCategories, activeIndustries, activeCountries, search])

  useEffect(() => {
    onChange(results)
  }, [results])

  return (
    <aside
      className={classNames(styles.container, {[styles.isVisible]: isVisible})}
      ref={containerRef}
    >
      {/* {channels && (
        <p className={styles.count}>
          {withCount(desktopChannelsCount, results ? results.length : channels.length)}
        </p>
      )} */}
      <div className={styles.header}>
        {searchHeading && <p className={styles.heading}>{searchHeading}</p>}
        <CloseS className={styles.closeIcon} onClick={handleClose} />
      </div>

      <div className={styles.search}>
        <input
          className={styles.input}
          placeholder={searchPlaceholder}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {categoriesHeading && <p className={styles.heading}>{categoriesHeading}</p>}
      <div className={styles.buttons}>
        {categories.map((category) => {
          const isActive = activeCategories.find((c) => c === category)
          return (
            <button
              key={category}
              className={classNames(styles.button, {
                [styles.isActive]: isActive,
              })}
              onClick={() =>
                setActiveCategories((prev) =>
                  isActive ? prev.filter((c) => c !== category) : prev.concat(category)
                )
              }
            >
              {t(translationNamespaces.CHANNELS_V2_CATEGORIES, category)}
            </button>
          )
        })}
      </div>

      {industriesHeading && <p className={styles.heading}>{industriesHeading}</p>}
      <div className={styles.buttons}>
        {industries.map((industry) => {
          const isActive = activeIndustries.find((i) => i === industry)

          return (
            <button
              key={industry}
              className={classNames(styles.button, {
                [styles.isActive]: isActive,
              })}
              onClick={() =>
                setActiveIndustries((prev) =>
                  isActive ? prev.filter((i) => i !== industry) : prev.concat(industry)
                )
              }
            >
              {t(translationNamespaces.CHANNELS_V2_INDUSTRIES, industry)}
            </button>
          )
        })}
      </div>

      {countriesHeading && <p className={styles.heading}>{countriesHeading}</p>}
      <div className={styles.buttons}>
        {countries.map((country) => {
          const isActive = activeCountries.find((c) => c === country)
          const ISO3166 = getName(translationNamespaces.CHANNELS_V2_COUNTRIES, country)

          return (
            <button
              key={country}
              className={classNames(styles.button, {
                [styles.isActive]: isActive,
              })}
              onClick={() =>
                setActiveCountries((prev) =>
                  isActive ? prev.filter((c) => c !== country) : prev.concat(country)
                )
              }
            >
              <span className={`fi fi-${ISO3166}`} />{' '}
              {t(translationNamespaces.CHANNELS_V2_COUNTRIES, country)}
            </button>
          )
        })}
      </div>

      <div className={styles.closeButton}>
        <Button
          label={withCount(mobileSearchButton, results ? results.length : channels?.length)}
          onClick={handleClose}
        />
      </div>
    </aside>
  )
}

export default ChannelsV2Filters
